import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {notify} from 'react-notify-toast';

import decksLibraryContent from '../lib/libraries/decks/index.jsx';

import CommunityProjectComponent from '../components/community-project/community-project.jsx';

import {connect} from 'react-redux';

import {
  openLoadingProject,
  closeLoadingProject,
  openCommunity,
  closeCommunityProject,
  openProjectStudent
} from '../reducers/modals';

import {
    activateDeck
} from '../reducers/cards';

import {
    loadProject
} from '../reducers/project';

import {setFullScreen} from '../reducers/community';

import ProjectApi from '../api/project';

class CommunityProject extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleInputChange',
            'handleComment',
            'handleFavoriteClick',
            'handleThumbsupClick',
            'handleEditClick',
            'handleDelClick'
        ]);
        this.state = {
          viewCount: 0,
          thumbsupCount: 0,
          favCount: 0,
          commentCount: 0,
          comment: '',
          commentList: [],
          vm: {},
          isThumbsup: false,
          isFav: false,
          isEdit: false,
          isDelete: false
        };
    }
    componentDidMount() {
      this.getProjectDetail(this.props.projectId);
      //
      this.props.vm.loadProject(this.props.projectData)
            .then(() => {
                console.log('project loaded');
            })
            .catch(error => {
              console.log(error);
              notify.show(error, '加载项目出错');
            });
    }
    handleFavoriteClick () {
      ProjectApi.addFavorites({project_id: this.props.projectId}).then(res => {
        this.getProjectDetail(this.props.projectId);
      }).catch( err => {
        console.log(err.message);
      });
    }
    handleThumbsupClick () {
      ProjectApi.addThumbsup({project_id: this.props.projectId}).then(res => {
        this.getProjectDetail(this.props.projectId);
      }).catch( err => {
        console.log(err.message);
      });
    }
    handleDelClick () {
      ProjectApi.deleteShare({id: this.props.projectId}).then(res => {
        this.props.onRequestClose(this.props.projectStudentVisible, false);
      }).catch( err => {
        console.log(err.message);
      });
    }
    handleEditClick () {
      this.props.vm.stopAll();
      this.props.onRequestClose(this.props.projectStudentVisible, true);

      this.props.openLoadingState();
      ProjectApi.getFileContent({id: this.props.projectId}).then(res=>{
        console.log(res);
        //将Blob 对象转换成 ArrayBuffer
        var reader = new FileReader();
        reader.readAsArrayBuffer(res);
        reader.onload = () => this.props.vm2.loadProject(reader.result)
            .then(() => {
                this.props.closeLoadingState();
                console.log(this.props.projectId);
                console.log(this.props.projectName);
                this.props.loadProject({id: this.props.projectId,name: this.props.projectName,instructions:'',credits: ''});
            })
            .catch(error => {
              console.log(error);
              notify.show(error, 'success');
            });
      });
    }
    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
    getProjectDetail(projectId) {
      if(this.props.isLoggedIn) {
        ProjectApi.get({id: projectId}).then(res =>{
          console.log(res);
          this.setState({
            commentList: res.comment_list,
            viewCount: res.view_count,
            thumbsupCount: res.thumbsup_count,
            favCount: res.fav_count,
            commentCount: res.comment_count,
            isThumbsup: res.isThumbsup,
            isFav: res.isFav,
            isDelete:  res.isDelete
          });
        })
      } else {
        ProjectApi.get2({id: projectId}).then(res =>{
          console.log(res);
          this.setState({
            commentList: res.comment_list,
            viewCount: res.view_count,
            thumbsupCount: res.thumbsup_count,
            favCount: res.fav_count,
            commentCount: res.comment_count,
            isThumbsup: res.isThumbsup,
            isFav: res.isFav,
            isDelete:  res.isDelete
          });
        })
      }

    }
    handleComment () {
      console.log('comment==========');
      console.log(this.props.projectId);
      const {comment} = this.state;
      if(comment.length ==0) return;

      ProjectApi.addComment({project_id: this.props.projectId, content: comment}).then(res => {
        //notify.show('添加评论成功', 'success');
        this.getProjectDetail(this.props.projectId);
      }).catch( err => {
        console.log(err.message);
      });

    }
    render () {
        const {
          visible,
          projectName,
          onRequestClose,
          ...componentProps
        } = this.props;
        if (!visible) return null;
        return (
            <CommunityProjectComponent
              id="communityProject"
              title={projectName}
              visible={visible}
              onRequestClose={() => {this.props.vm.stopAll();this.props.onRequestClose(this.props.projectStudentVisible,false);}}
              viewCount={this.state.viewCount}
              thumbsupCount={this.state.thumbsupCount}
              favCount={this.state.favCount}
              commentCount={this.state.commentCount}
              commentList={this.state.commentList}
              isLoggedIn={this.props.isLoggedIn}
              isThumbsup={this.state.isThumbsup}
              isFav={this.state.isFav}
              isEdit={this.state.isEdit}
              isDelete={this.state.isDelete}
              onInputChange={this.handleInputChange}
              onComment={this.handleComment}
              onFav={this.handleFavoriteClick}
              onThumbsup={this.handleThumbsupClick}
              onEdit={this.handleEditClick}
              onDel={this.handleDelClick}
              {...componentProps}
            />
        );
    }
}

CommunityProject.propTypes = {
    onRequestClose: PropTypes.func,
    visible: PropTypes.bool,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    })
};

const mapStateToProps = state => ({
  isLoggedIn: state.scratchGui.user.isLoggedIn,
  isEdit: state.scratchGui.user.type === 2 && state.scratchGui.user.curModal === 'projectStudent',
  projectId: state.scratchGui.community.projectId,
  projectName: state.scratchGui.community.projectName,
  projectData: state.scratchGui.community.projectData,
  visible: state.scratchGui.modals.communityProject,
  projectStudentVisible: state.scratchGui.user.curModal === 'projectStudent',
  vm: state.scratchGui.community.vm,
  isFullScreen: state.scratchGui.community.isFullScreen,
  vm2: state.scratchGui.vm
});

const mapDispatchToProps = dispatch => ({
  closeLoadingState: () => dispatch(closeLoadingProject()),
  openLoadingState: () => dispatch(openLoadingProject()),
  onRequestClose: (projectStudentVisible, isOpenEdit) => {
    dispatch(closeCommunityProject());
    if (projectStudentVisible) {
      if(!isOpenEdit){
        dispatch(openProjectStudent());
      }
    }else {
        dispatch(openCommunity());
    }
  },
  loadProject: data => dispatch(loadProject(data)),
  onSetStageFull: () => dispatch(setFullScreen(true)),
  onSetStageUnFull: () => dispatch(setFullScreen(false))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityProject);
