import { stringify } from 'qs';
import request from '../utils/request';

export default {
  create(params) {
    return request('/api/project/create', {
      method: 'POST',
      body: params
    });
  },
  update(params) {
    return request('/api/project/update', {
      method: 'POST',
      body: params
    });
  },
  findUserProjects(params) {
    return request('/api/project/findByUserId', {
      method: 'POST',
      body: params
    });
  },
  findUserFavProjects(params) {
    return request('/api/project/findUserFavProjects', {
      method: 'POST',
      body: params
    });
  },
  findStudentProjects(params) {
    return request('/api/project/findByUsername', {
      method: 'POST',
      body: params
    });
  },
  getFileContent(params) {
    return request(`/api/project/getFileContent?${stringify(params)}`,{
      methodx: 'DOWNLOAD'
    });
  },
  share(params) {
    return request('/api/project/share', {
      method: 'POST',
      body: params
    });
  },
  deleteShare(params) {
    return request('/api/project/deleteShare', {
      method: 'POST',
      body: params
    });
  },
  findSharedProjects(params) {
    return request('/api/project/findShared');
  },
  get(params) {
    // return request('/api/project/get', {
    //  method: 'POST',
    //   body: params
    // });
    return request(`/api/project/get?${stringify(params)}`);
  },
  get2(params) {
    return request(`/api/project/get2?${stringify(params)}`);
  },
  addComment(params) {
    return request('/api/projectComment/create', {
      method: 'POST',
      body: params
    });
  },
  addThumbsup(params) {
    return request('/api/projectThumbsup/create', {
      method: 'POST',
      body: params
    });
  },
  addFavorites(params) {
    return request('/api/projectFavorite/create', {
      method: 'POST',
      body: params
    });
  },
  addViewCount(params) {
    return request('/api/project/addViewCount', {
      method: 'POST',
      body: params
    });
  }
};
