import 'whatwg-fetch';
//import 'es6-promise'

import {notify} from 'react-notify-toast';
import sessionstorage from './sessionstorage'

//const domain = 'http://scratch.songzheng.net/api.php'
const domain = 'https://scratch.robotutor.cn/api.php'
//const domain = 'https://scratch.yuanchuangcat.com/api.php'
//const domain = 'https://ycm.rrke.net/api.php'
//const domain = 'https://scratch.yaoyouyun.cn/api.php'
//const domain = 'https://www.scratchgui.cn/api.php'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  notify.show(errortext,'error');
  console.log(`请求错误 ${response.status}: ${response.url}: ${errortext}`);

  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  url = domain + url;
  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'DELETE') {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }else{
    newOptions.headers = {
      Accept: 'application/json',
      ...newOptions.headers,
    };
  }

  let token = sessionstorage.getItem('token');
  // console.log(token);
  if(token !=null ){
    newOptions.headers = {
      Authorization: 'Bearer ' + token,
      ...newOptions.headers,
    };
  }
  console.log(newOptions);

  return new Promise((resolve, reject) => {
    fetch(url, newOptions)
    .then(checkStatus)
    .then(response => {
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return response.text();
      }
      if (newOptions.methodx === 'DOWNLOAD'){
        return response.blob();
      }
      return response.json();
    })
    .then(jsonRes => {
      if (newOptions.methodx=== 'DOWNLOAD'){
        resolve(jsonRes);
      }
      switch (jsonRes.code) {
          case 200:
            resolve(jsonRes.data);
            break;
          case 1001:
            reject(new Error(jsonRes.msg));
            break;
          case 1003:
          case 3001:
            reject(new Error(jsonRes.data + '已存在'));
            break;
          case 4001:
          case 4002:
          case 4003:
          case 4004:
          case 4005:
          case 5001:
            reject(new Error(jsonRes.msg));
            break;
          default:
            reject(new Error('系统错误1，请和管理员联系'));
        }
    })
    .catch(e => {
      //const { dispatch } = store;
      const status = e.name;
      console.log(e);
      console.log('status code: ' + status);
      if (status === 401) {
        console.log('please login first');
        return;
      }
      if (status === 403) {
      //  dispatch(routerRedux.push('/exception/403'));
        return;
      }
      if (status <= 504 && status >= 500) {
      //  dispatch(routerRedux.push('/exception/500'));
        return;
      }
      if (status >= 404 && status < 422) {
      //  dispatch(routerRedux.push('/exception/404'));
      }
      reject(e);
    });
  });

}
